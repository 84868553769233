import React from 'react'
import './joinCourseModal.scss'
import * as Step from '../steps'
import ProgressBreadcrumb from '../../../../theme/commons/progress_breadcrumb'
import StandardModal from '@theme/commons/standard_modal/StandardModal'
import { language } from '@theme/commons/language/language'
import ButtonLink from '@theme/commons/button_link/ButtonLink'
import JOIN_COURSE_STEP from '../../joinCourseStep'

/**
 * @typedef IconsArrayItems
 * @property {Array<string>} value
 * @property {number} id
 */

/**
 * Enum for step icons
 * @typedef {Array<IconsArrayItems>} IconsArray
 * @example
 * [
 *     { value: ['far', 'file-alt'], id: 0 },
 *     { value: ['far', 'credit-card'], id: 1 },
 *     { value: ['fas', 'check'], id: 2 }
 * ]
 */
const ICONS_ARRAY = [
    { value: ['far', 'file-alt'], id: 0 },
    { value: ['far', 'credit-card'], id: 1 },
    { value: ['fas', 'check'], id: 2 }
]

/**
 * @typedef JoinCourseModalProps
 * @memberOf JoinCourseModal
 * @property {function} onClose
 * @property {number} step
 * @property {function} onNext
 * @property {string} price
 * @property {string} courseName
 * @property {string} eventsGroupId
 * @property {string} productId
 */

/**
 * Breadcrumb to show steps navigation
 * @class JoinCourseModal
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {JoinCourseModalProps} props
 * @returns {React.ReactNode}
 * @example
 * <JoinCourseModal
 *    courseName={courseName}
 *    eventsGroupId={courseId}
 *    productId={productId}
 *    onClose={onClose}
 *    onNext={handleOnNext}
 *    price={price}
 *    step={validatedCurrentStep}
 * />
 */
const JoinCourseModal = ({ onClose, step, onNext, price, courseName, eventsGroupId, vendorId, kioskId, productId }) => {
    const StepMap = {
        [JOIN_COURSE_STEP.LOGIN]: <Step.Login onNext={onNext} />,
        [JOIN_COURSE_STEP.PAYMENT]: <Step.Payment price={price} courseName={courseName} onNext={onNext} eventsGroupId={eventsGroupId} vendorId={vendorId} kioskId={kioskId} productId={productId} />,
        [JOIN_COURSE_STEP.SUCCESSFUL]: <Step.Success onNext={onNext} price={price} courseName={courseName} />
    }

    return (
        <StandardModal
            onClose={onClose}
            size="md"
            title={language.modals.joinCourse}
            show={true}
            className="join-course-modal"
            centered
            isMobileModalHidden
        >
            <div className={'join-course-modal-content'}>
                <div className="breadcrumb-container">
                    <ProgressBreadcrumb inProgressIndex={step - 1} iconsArray={ICONS_ARRAY} />
                </div>
                <div className="step-container">
                    {StepMap[step]}
                </div>
                {step < JOIN_COURSE_STEP.SUCCESSFUL && <ButtonLink onClick={onClose} />}
            </div>
        </StandardModal>
    )
}

export default JoinCourseModal
