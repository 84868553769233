import { getLanguage } from '../../../utils/languageUtils'

const en = 'en'
const de = 'de'

const selectedLanguage = getLanguage()

export const cmsLanguageEng = {
    content: {
        text_5c2a5: 'Text',
        membersarea_5c2a5: 'Members Area',
        trainingscheduleweek_5c2a5: 'Training Schedule Weekends',
        wirsinddersport_5c2a5: 'Wir sind der Sport',
        seit1945_5c2a5: 'seit 1945',
        vflwolfsburgevelster_5c2a5: 'VfL Wolfsburg e.V. Elsterweg 5, 38446 Wolfsburg',
        fillouttheformbelowa_5c2a5: 'Fill out the form below and send us your request. We will contact you shortly.',
        becomeamember_5c2a5: 'Become a member',
        share: 'Share',
        comment: 'Comment',
        noData: 'No Data',
        pageNotFoundErrorText: '404, page not found',
        pageNotFoundText: 'The page you are currently looking for doesn’t exist. Get up and try again!',
        pageNotFoundButtonText: 'Back to home',
        messageSent: 'Your message was sent successfully!',
        memberAreaCourseDate: 'Course Date',
        memberAreaCourse: 'Course',
        memberAreaAmount: 'Amount',
        memberAreaPaymentDate: 'Payment date'
    },
    defaultContent: {
        home: {
            pageTitle: 'Welcome'
        }
    },
    cms: {
        administratorArea: 'Administrator Area',
        editContent: 'Edit content',
        saveChanges: 'Save changes',
        removeChanges: 'Remove changes and cancel',
        showAddTemplates: 'Show add templates',
        hideAddTemplates: 'Hide add templates',
        edit: 'Edit',
        add: 'Add',
        acceptChanges: 'Accept changes',
        cancelChanges: 'Cancel changes',
        revertChanges: 'Revert changes',
        deleteTemplate: 'Delete template',
        deleteContent: 'Delete content',
        delete: 'Delete',
        titlePlaceholder: 'Add a title',
        textPlaceholder: 'Add a text',
        numberPlaceholder: 'Add a number',
        descriptionPlaceholder: 'Add a description',
        linkPlaceholder: 'Add a link',
        searchPlaceholder: 'Search',
        class: 'Class',
        classSchedule: 'Class schedule',
        addClass: 'Add class',
        editClass: 'Edit class',
        deleteClass: 'Delete class',
        viewCourse: 'View course',
        photoPlaceholder: 'Select a picture on your computer',
        maxPictureSize: 'Max picture size',
        videoPlaceholder: 'Add a link from a Youtube or Vimeo video',
        datePlaceholder: 'Add a date',
        phonePlaceholder: 'Add a phone number',
        pricePlaceholder: 'Add a price',
        validFormats: 'Valid formats',
        emailPlaceholder: 'Add an email',
        ibanPlaceholder: 'Add an IBAN',
        templatePreview: 'Template preview',
        menuPreview: 'Menu preview',
        addNewTemplate: 'Add new template',
        addNewElement: 'Add new',
        createNewContent: 'Create new page',
        createPage: 'create page',
        selectLocation: 'Select location',
        manageContent: 'Manage content',
        createContent: 'Create content',
        chooseASection: 'Choose a Heading',
        chooseASubSection: 'Choose a Title',
        chooseAMenu: 'Choose a Menu',
        chooseATitle: 'Choose a Subtitle',
        chooseASubTitle: 'Choose a Page Heading',
        introduceName: 'Introduce name',
        continue: 'continue',
        cancel: 'cancel',
        done: 'Done',
        removeContent: 'You are going to remove this content.',
        removeContentDescription: "This action is going to remove: \n - All templates associated to this content. \n - Navigation from the menu. \n - All associated contents. \nYou can't recover it later.",
        contentConfiguration: 'CONTENT CONFIGURATION',
        editNavigation: 'Edit navigation',
        editOwnNavigationBar: 'Edit own navigation bar',
        blockNavigation: 'You are going to remove your changes.',
        blockNavigationDescription: 'This action is going to remove all changes done \nwhile the edition',
        searchPhotoFromMesh: 'Search photo from mesh',
        searchImageByLabel: 'Search images by label',
        nextTitle: 'Next Title',
        selectFromDropdown: 'Please select values from both dropdowns.',
        view: 'View',
        chooseTemplate: 'Choose a template',
        goToCourse: 'Go To Course',
        okButton: 'OK',
        myCourses: 'My Courses',
        selectCourses: 'Select Courses',
        categories: 'Categories',
        week: 'Week',
        goToInstagram: 'Go to Instagram'
    },
    priceListItem: {
        adults: 'Adults',
        marriedCouples: 'Married couples',
        family: 'Family',
        childrenYoungPeople: 'Children & young people',
        pupilsStudentsTrainees: 'Pupils from 16 years, students, trainees*',
        federalVolunteerService: 'Federal Volunteer Service*',
        peopleWithDisabilities: 'People with disabilities*',
        peopleWithDisabilitiesMarried: 'People with disabilities/ married couples*',
        peopleWithDisabilitiesFamily: 'People with disabilities/ family*',
        pensioner: 'Pensioner*'
    },
    error: {
        mandatory: 'This field is mandatory',
        noValidUrl: 'Invalid URL',
        pictureLarge: 'Picture too large',
        noValidForm: 'Form is not valid',
        noValidPostCode: 'Post code is not valid',
        notValidVideo: 'It is not a valid Youtube or Vimeo link',
        noValidPhone: 'Invalid phone number',
        noValidEmail: 'Invalid email',
        noValidPrice: 'Invalid price',
        noCourseSelected: 'First Choose Your Sport Section in the Dropdown',
        pendingInitializeMenus: 'Pending initialization of the menus.',
        overlappingClasses: 'Classes are overlapping',
        invalidField: 'Invalid field',
        invalidFields: 'One or more fields have errors',
        notValidIban: 'IBAN number should be between 16 to 34 letters.',
        notValidCard: 'Invalid card number',
        notValidSecurityNumber: 'Invalid CVV code',
        notValidExpiryDate: 'Invalid expiry date',
        notValidNumber: 'Invalid number',
        notValidDuration: 'Duration is required. Please select one from dropdown.',
        mustNotBeEmpty: 'must not be empty',
        fieldEmpty: 'field empty',
        postCodeInvalid: 'post code invalid',
        textInvalid: 'text invalid',
        bicInvalid: 'bic invalid',
        shouldFixAllErrors: 'You should fix all the input errors',
        unexpectedError: 'There was an unexpected error. Your message could not be sent.',
        orderIsNotAccepted: 'Order is not accepted',
        orderNotReceived: 'Order has not been received properly',
        addressMandatory: 'Address fields must be filled all or none'
    },
    tooltip: {
        inputRevert: 'Recover original value',
        inputDelete: 'Delete element',
        noAvailableToDelete:
            'You can’t remove this template because it’s mandatory',
        noValidVideo: 'Only Youtube or Vimeo video links are valid',
        chooseATemplate: 'Choose a template and complete the fields',
        chooseAMenu: 'Choose a menu and complete the fields',
        chooseAContent: 'Choose where to locate a new content by selecting an available field combination',
        priceValidFormat: '123.45'
    },
    infoModal: {
        successIntro: 'Well done',
        successMessage: 'You successfully saved your data.',
        successButtonText: 'OK',
        failIntro: 'Oooops',
        failMessage: 'Something went wrong. Please try again.',
        failButtonText: 'OK',
        defaultIntro: 'For your information',
        defaultMessage: 'Here goes a message.',
        defaultButtonText: 'got it'
    },
    templatesNames: {
        carousel: 'Full screen carousel',
        greyBanner: 'Grey banner with button',
        textArea: 'Text',
        actionButtonBanner: 'White banner with button',
        video: 'Video',
        smallCarousel: 'Small pictures with text carousel',
        parallax: 'Parallax with text',
        marquesina: 'Sponsors',
        commonBanner: 'Image banner with title',
        accordion: 'Accordion',
        profiles: 'Profiles',
        contact: 'Contact details with form',
        photoGallery: 'Photo gallery',
        homeNews: 'News banner with button',
        courseInfo: 'Course information',
        membersForm: 'Members Form',
        instagram: 'Instagram'
    },
    menuNames: {
        topMenu: 'Top Navigation',
        headerMenu: 'Global Navigation',
        socialMediaMenu: 'Social Media Navigation',
        footerMenu: 'Footer Navigation',
        subPageMenu: 'Sub-page Navigation',
        mainMenu: 'main menu',
        pageMenu: 'page menu',
        footerContactMenu: 'Contact Us'
    },
    sectionNames: {
        sports: 'Sports',
        courses: 'Courses',
        culture: 'Culture',
        whoWeAre: 'Who We Are',
        addNewOne: 'Add New One',
        newSection: 'New Section',
        newSubSection: 'New Sub-section',
        newTitle: 'New Title',
        newSubTitle: 'New Sub-title',
        home: 'Home',
        trainingSchedule: 'Training Schedule'
    },
    inputFields: {
        video: 'Video',
        text: 'Text',
        description: 'Description',
        date: 'Date',
        durationForm: 'Course duration',
        classInfo: 'Class Info',
        className: 'Class name',
        classDuration: 'Class duration',
        classDurationUnit: 'Class duration unit',
        duration: 'Duration',
        durationMin: 'Duration (min)',
        location: 'Location',
        unit: 'unit',
        choose: 'Choose',
        price: 'Price',
        endDate: 'End Date',
        every: 'Every',
        picture: 'Picture',
        repeat: 'Repeat',
        title: 'Title',
        subtitle: 'Subtitle',
        subTitle: 'Page section',
        linkText: 'Link text',
        link: 'link',
        time: 'Time',
        firstHashtag: 'First hashtag',
        secondHashtag: 'Second hashtag',
        textButton: 'Button text',
        linkButton: 'Button link',
        name: 'Name',
        fullName: 'Full name',
        surname: 'Surname',
        displayName: 'Display name',
        birthDate: 'Birth date',
        gender: 'Gender',
        streetNumner: 'Street, number',
        postCode: 'Post code',
        city: 'City',
        role: 'Role',
        trainer: 'Trainer',
        trainers: 'Trainers',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        assignedMenu: 'menu',
        assignedSection: 'heading',
        assignedSubSection: 'title',
        assignedPageMenu: 'subtitle',
        assignedPageSection: 'heading',
        euros: 'Euros',
        currency: 'Currency',
        noRepeat: 'No repeat',
        weekly: 'Weekly',
        monthly: 'Monthly',
        daily: 'Daily',
        minutes: 'Minutes',
        repeatDropdownDoesNotRepeat: 'Does not repeat',
        repeatDropdownDaily: 'Daily',
        repeatDropdownWeeklyOnMonday: 'Weekly On Monday',
        repeatDropdownEveryWeekDayMondayToFriday: 'Every Week Day (Monday - Friday)',
        repeatDropdownCustom: 'Custom...',
        endsOn: 'Ends on',
        repeatOn: 'Repeat on',
        repeatEvery: 'Repeat every',
        select: 'Select',
        Never: 'Never',
        On: 'On',
        After: 'After',
        numberAbbr: 'No.',
        customRecurrence: 'CUSTOM RECURRENCE',
        occurances: 'Occurances',
        message: 'Message',
        send: 'Send',
        retryButton: 'Retry',
        male: 'Male',
        female: 'Female',
        accessToken: 'AccessToken',
        appId: 'AppId',
        clientToken: 'ClientToken',
        instagramUrl: 'Instagram Account Url',
        phone1: 'Phone Number 1',
        phone2: 'Phone Number 2',
        backgroundImage: 'Background Image',
        cardNumberPlaceholder: 'Card number',
        cardNamePlaceholder: 'Name on card',
        expiryDatePlaceholder: 'Expiry date',
        expiryMonthPlaceholder: 'MM',
        expiryYearPlaceholder: 'YYYY',
        securityCodePlaceholder: 'Security code',
        shortDescription: 'Short Description'
    },
    infoBox: {
        parentText1: 'The new page will be located in ',
        parentText2: ' of the ',
        parentText3: '. Click "CONTINUE" to introduce page name and its contents.',
        titleText1: 'A new page will be created with this content in its top banner. ',
        titleText2: 'The same title ',
        titleText3: ' will also be used as menu name and route name. Once created you may add further content.'
    },
    initializerData: {
        membersMenuName: 'Members',
        becomeAMemberMenuName: 'Become a member',
        sportsMenuName: 'Sports',
        coursesMenuName: 'Courses',
        whoWeAreMenuName: 'Who we are',
        cultureMenuName: 'Culture',
        trainingScheduleName: 'Training schedule',
        informationMenuName: 'Information',
        contactUsMenuName: 'Contact Us',
        SocialMediaMenuName: 'Social Media',
        newsMenuName: 'News'
    },
    profile: {
        modal: {
            user: 'User data',
            image: 'Profile image',
            persistSuccess: '%FIELD% was successfully saved',
            persistFailed: '%FIELD% could not be saved'
        },
        profile: 'Profile',
        associatedProfile: 'Associated Profiles',
        paymentMethod: 'Payment Methods',
        joinCourse: 'Join a course',
        saveChanges: 'Save changes',
        memberSince: 'Member since',
        noCourses: 'You haven’t joined any courses yet'
    },
    months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
    },
    weekday: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    },
    news: {
        more: 'More'
    }
}

export const cmsLanguageDeu = {
    content: {
        text_5c2a5: 'Text',
        membersarea_5c2a5: 'Mitgliederbereich',
        trainingscheduleweek_5c2a5: 'Trainingsplan',
        wirsinddersport_5c2a5: 'Wir sind der Sport',
        vflwolfsburgevelster_5c2a5: 'VfL Wolfsburg e.V. Elsterweg 5, 38446 Wolfsburg',
        fillouttheformbelowa_5c2a5: 'Füllen Sie das untenstehende Formular aus und senden Sie uns Ihre Anfrage. Wir werden Sie in Kürze kontaktieren.',
        becomeamember_5c2a5: 'Mitglied werden',
        seit1945_5c2a5: 'seit 1945',
        comment: 'Kommentar',
        noData: 'Keine Daten',
        pageNotFoundErrorText: '404 Seite nicht gefunden',
        pageNotFoundText: 'Die Seite, die Sie gerade suchen, existiert nicht. Steh auf und versuche es noch einmal!',
        pageNotFoundButtonText: 'Zurück nach Hause',
        messageSent: 'Deine Nachricht wurde erfolgreich gesendet!',
        memberAreaCourseDate: 'Kursdatum',
        memberAreaCourse: 'Kurs',
        memberAreaAmount: 'Menge',
        memberAreaPaymentDate: 'Zahlungsdatum'
    },
    defaultContent: {
        home: {
            pageTitle: 'Willkommen'
        }
    },
    cms: {
        blockNavigation: '',
        blockNavigationDescription: '',
        administratorArea: 'Administratorbereich',
        editContent: 'Inhalt bearbeiten',
        saveChanges: 'Änderungen sichern',
        joinCourse: 'Kurs beitreten',
        removeChanges: 'Änderungen entfernen und abbrechen',
        showAddTemplates: 'Vorlagen zeigen',
        hideAddTemplates: ' Vorlagen ausblenden ',
        edit: 'Bearbeiten',
        add: 'Hinzufügen',
        acceptChanges: 'Änderungen akzeptieren',
        cancelChanges: 'Änderungen löschen',
        revertChanges: 'Änderungen rückgänig machen',
        deleteTemplate: 'Vorlage löschen',
        deleteContent: 'Inhalt löschen',
        delete: 'Löschen',
        titlePlaceholder: 'Überschrift hinzufügen',
        textPlaceholder: 'Text hinzufügen',
        numberPlaceholder: 'Nummer hinzufügen',
        descriptionPlaceholder: 'Beschreibung hinzufügen',
        linkPlaceholder: 'Link hinzufügen',
        searchPlaceholder: 'Suchen',
        class: 'Klasse',
        classSchedule: 'Stundenplan',
        addClass: 'Kurs hinzufügen',
        editClass: 'Kurs bearbeiten',
        deleteClass: 'Kurs löschen',
        viewCourse: 'Kurs anzeigen',
        photoPlaceholder: ' Wählen Sie ein Bild von Ihrem Computer ',
        maxPictureSize: 'Max Bildgröße (kb)',
        videoPlaceholder: 'Link von Youtube oder Vimeo hinzufügen',
        datePlaceholder: 'Datum hinzufügen',
        phonePlaceholder: 'Telefonnummer hinzufügen',
        pricePlaceholder: 'Preis hinzufügen',
        validFormats: 'Gültige Formate',
        emailPlaceholder: 'E-Mail hinzufügen',
        ibanPlaceholder: 'IBAN hinzufügen',
        templatePreview: 'Vorschau Vorlage',
        menuPreview: 'Vorschau Menue',
        addNewTemplate: 'Neue Vorlage hinzufügen',
        addNewElement: 'Neues hinzufügen',
        createNewContent: 'Neue Seite erstellen',
        createPage: 'Seite erstellen',
        selectLocation: 'Ort auswählen',
        manageContent: 'Inhalt verwalten',
        createContent: 'Inhalt erstellen',
        chooseASection: 'Wählen Sie einen Abschnitt',
        chooseASubSection: 'Titel auswählen',
        chooseAMenu: 'Menü auswählen ',
        chooseATitle: 'Titel auswählen',
        chooseASubTitle: 'Sub-titel auswählen',
        introduceName: 'Namen eingeben',
        introduceAddress: 'Adresse einführen',
        continue: 'weiter',
        cancel: 'löschen',
        done: 'Erledigt',
        removeContent: ' Diese Inhalt werden entfernen.',
        removeContentDescription: ' Damit wird folgendes entfernt: \n - Alle Vorlagen, die mit diesem Inhalt verknüpft sind. \n - Navigation im Menü. \n - Alle dazugehörigen Inhalte. \n Sie können später nicht wieder hergestellt werden.',
        contentConfiguration: ' KONFIGURATION DES INHALTS ',
        editNavigation: 'Navigation bearbeiten',
        editOwnNavigationBar: ' Eigene Navigationsleiste bearbeiten ',
        searchPhotoFromMesh: 'Suche Foto von Mesh',
        searchImageByLabel: 'Bilder nach Label suchen',
        nextTitle: 'Nächster Titel',
        selectFromDropdown: 'Bitte wählen Sie Werte aus beiden Dropdowns aus.',
        view: 'Sicht',
        chooseTemplate: 'Wählen Sie eine Vorlage',
        goToCourse: 'Gehe zum Kurs',
        okButton: 'OK',
        myCourses: 'Meine Kurse',
        selectCourses: 'Kurse auswählen',
        categories: 'Kategorien',
        week: 'Woche',
        goToInstagram: 'Go to Instagram'
    },
    priceListItem: {
        adults: 'Erwachsene',
        marriedCouples: 'Ehepaare',
        family: 'Familie',
        childrenYoungPeople: 'Kinder & Jugendliche',
        pupilsStudentsTrainees: 'Schüler ab 16 Jahren, Studenten, Auszubildende*',
        federalVolunteerService: 'Bundesfreiwilligendienst*',
        peopleWithDisabilities: 'Menschen mit Behinderungen*',
        peopleWithDisabilitiesMarried: 'Menschen mit Behinderungen/Ehepaare*',
        peopleWithDisabilitiesFamily: 'Menschen mit Behinderungen/Familie*',
        pensioner: 'Rentner*'
    },
    error: {
        mandatory: 'Diese Angabe ist freiwillig',
        noValidUrl: 'Ungültige URL',
        pictureLarge: 'Bild zu groß (kb)',
        noValidForm: 'Form ist ungültig',
        noValidPostCode: 'Postleitzahl ist ungültig',
        notValidVideo: 'Ungültiger Youtube- oder Vimeo Link',
        noValidPhone: 'Ungültige Telefonnummer',
        noValidEmail: 'Ungültige E-Mail',
        noValidPrice: 'Unqültige Preis',
        noCourseSelected: 'Wählen Sie zuerst Ihren Kurs im Dropdown-Menü',
        pendingInitializeMenus: 'Pending initialization of the menus.',
        overlappingClasses: 'Die Kurse überschneiden sich',
        invalidField: 'ungültiges Feld',
        invalidFields: 'Ein oder mehrere Felder weisen Fehler auf',
        notValidIban: 'Die IBAN-Nummer sollte zwischen 16 und 34 Buchstaben haben.',
        notValidCard: 'Ungültige Kartennummer',
        notValidSecurityNumber: 'Ungültiger CVV-Code',
        notValidExpiryDate: 'Ungültiges Ablaufdatum',
        notValidNumber: 'Ungültige Nummer',
        notValidDuration: 'Dauer ist erforderlich. Bitte wählen Sie eine aus der Dropdown-Liste aus.',
        mustNotBeEmpty: 'darf nicht leer sein',
        fieldEmpty: 'feld leer',
        postCodeInvalid: 'postleitzahl ungültig',
        textInvalid: 'text ungültig',
        bicInvalid: 'bic ungültig',
        shouldFixAllErrors: 'Sie sollten alle Eingabefehler beheben',
        unexpectedError: 'Es ist ein unerwarteter Fehler aufgetreten. Deine Nachricht konnte nicht gesendet werden.',
        orderIsNotAccepted: 'Bestellung wird nicht angenommen',
        orderNotReceived: 'Die Bestellung ist nicht ordnungsgemäß eingegangen',
        addressMandatory: 'Adressfelder müssen alle oder keine ausgefüllt werden'
    },
    tooltip: {
        inputRevert: 'Ursprünglichen Wert wiederherstellen',
        inputDelete: 'Element löschen',
        noAvailableToDelete: ' Sie können diese Vorlage nicht entfernen, da sie notwendig ist.',
        noValidVideo: 'Nur Youtube oder Vimeo video Links sind möglich',
        chooseATemplate: 'Wähle eine Vorlage und fülle die Felder aus',
        chooseAMenu: ' Menü wählen und Felder ausfüllen',
        chooseAContent: ' Wählen Sie aus, wo ein neuer Inhalt platziert werden soll, indem Sie eine verfügbare Feldkombination auswählen ',
        priceValidFormat: '123.45'
    },
    infoModal: {
        successIntro: 'Gut gemacht',
        successMessage: 'Sie haben Ihre Daten erfolgreich gespeichert.',
        successButtonText: 'OK',
        failIntro: 'Hoppla',
        failMessage: 'Etwas ist schief gelaufen. Bitte versuche es erneut.',
        failButtonText: 'OK',
        defaultIntro: 'Für Ihre Information',
        defaultMessage: 'Hier geht eine Nachricht.',
        defaultButtonText: 'Habe est'
    },
    templatesNames: {
        carousel: 'Großes Karusell (Full-screen)',
        greyBanner: 'Grauer Banner mit Button',
        textArea: 'Text',
        actionButtonBanner: 'Weißer Banner mit Button',
        video: 'Video',
        smallCarousel: 'Kleine Bilder mit Textkarussell',
        parallax: 'Parallax mit Text',
        marquesina: 'Sponsoren',
        commonBanner: 'Bildbanner mit Titel',
        accordion: 'Accordion',
        profiles: 'Profil',
        contact: 'Formular mit Kontaktangaben',
        photoGallery: 'Fotogallerie',
        homeNews: 'News-Banner mit Schaltfläche',
        courseInfo: 'Kurs Information',
        membersForm: 'Mitgliederformular',
        instagram: 'Instagram'
    },
    menuNames: {
        topMenu: 'Top Navigation',
        headerMenu: 'Globale Navigation',
        socialMediaMenu: 'Social Media Navigation',
        footerMenu: 'Footer Navigation',
        subPageMenu: 'Sub-page Navigation',
        mainMenu: 'Hauptmenü',
        pageMenu: 'Seitenmenü',
        footerContactMenu: 'Kontaktiere uns'
    },
    sectionNames: {
        sports: 'Sport',
        courses: 'Kurse',
        culture: 'Kultur',
        whoWeAre: 'Wer wir sind',
        addNewOne: 'Neues hinzufügen',
        newSection: 'Neue Section',
        newSubSection: 'Neuer Unterabschnitt',
        newTitle: 'Neuer Titel',
        newSubTitle: 'Neuer Sub-title',
        home: 'Home',
        trainingSchedule: 'Trainingsplan '
    },
    inputFields: {
        video: 'Video',
        text: 'Text',
        description: 'Beschreibung',
        date: 'Datum',
        durationForm: 'Kursdauer',
        classInfo: 'Info',
        className: 'Name',
        classDuration: 'Unterrichtsdauer',
        classDurationUnit: 'Unterrichtsdauer Einheit',
        duration: 'Dauer',
        durationMin: 'Dauer (Minuten)',
        location: 'Standort',
        unit: 'Einheit',
        choose: 'Wählen',
        price: 'Preis',
        endDate: 'Ende',
        every: 'Turnus',
        picture: 'Bild',
        repeat: 'Wiederholen',
        title: 'Überschrift',
        subtitle: 'Subt-titel',
        subTitle: 'Seitenabschnitt',
        linkText: 'Link text',
        link: 'Link',
        time: 'Zeit',
        firstHashtag: 'Erster Hashtag',
        secondHashtag: 'Zweiter Hashtag',
        textButton: 'Schaltflächentext',
        linkButton: 'Button link',
        name: 'Name',
        fullName: 'Vollständiger Name',
        surname: 'Nachname',
        displayName: 'Anzeigename',
        birthDate: 'Geburtsdatum',
        gender: 'Geschlecht',
        streetNumner: 'Straße, Hausnummer',
        postCode: 'Postleitzahl',
        city: 'Stadt',
        role: 'Funktion',
        trainer: 'Trainer/in',
        trainers: 'Trainer/in',
        email: 'E-Mail',
        phone: 'Telefon',
        address: 'Adresse',
        assignedMenu: 'Menü',
        assignedSection: 'überschrift',
        assignedSubSection: 'Titel',
        assignedPageMenu: 'sub-titel',
        assignedPageSection: 'überschrift',
        euros: 'Euro',
        currency: 'Währung',
        noRepeat: 'Keine Wiederholung',
        weekly: 'Wöchentlich',
        monthly: 'Monatlich',
        daily: 'Täglich',
        minutes: 'Minuten',
        repeatDropdownDoesNotRepeat: 'Wiederholt sich nicht',
        repeatDropdownDaily: 'Täglich',
        repeatDropdownWeeklyOnMonday: 'Wöchentlich am Montag',
        repeatDropdownEveryWeekDayMondayToFriday: 'Jeden Wochentag (Montag - Freitag)',
        repeatDropdownCustom: 'Brauch...',
        endsOn: 'Endet am',
        repeatOn: 'Wiederholen Sie auf',
        repeatEvery: 'Wiederhole jeden',
        select: 'Wählen',
        Never: 'Niemals',
        On: 'An',
        After: 'Nach',
        numberAbbr: 'Nummer',
        customRecurrence: 'BENUTZERDEFINIERTE WIEDERHOLUNG',
        occurances: 'Vorkommen',
        message: 'Nachricht',
        send: 'Schicken',
        retryButton: 'Wiederholen',
        male: 'Männlich',
        female: 'Weiblich',
        accessToken: 'AccessToken',
        appId: 'AppId',
        clientToken: 'ClientToken',
        instagramUrl: 'Instagram-Konto-URL',
        phone1: 'Telefonnummer 1',
        phone2: 'Telefonnummer 2',
        backgroundImage: 'Hintergrundbild',
        cardNumberPlaceholder: 'Kartennummer',
        cardNamePlaceholder: 'Name auf der Karte',
        expiryDatePlaceholder: 'Verfallsdatum',
        expiryMonthPlaceholder: 'MM',
        expiryYearPlaceholder: 'YYYY',
        securityCodePlaceholder: 'Sicherheitscode',
        shortDescription: 'Kurze Beschreibung'
    },
    infoBox: {
        parentText1: ' Die neue Seite befindet sich in ',
        parentText2: ' in der ',
        parentText3: '. Click "WEITER" um den Namen der Seite und ihren Inhalt darzustellen.',
        titleText1: ' Es wird eine neue Seite mit diesem Inhalt im Seitenkopf erstellt. ',
        titleText2: 'Die gleiche Überschrift',
        titleText3: ' wird auch als Menüname und Pfadname verwendet. Nach der Erstellung können weitere Inhalte hinzugefügt werden.'
    },
    initializerData: {
        membersMenuName: 'Mitglied',
        becomeAMemberMenuName: 'Mitglied werden',
        sportsMenuName: 'Sport',
        coursesMenuName: 'Kurs',
        whoWeAreMenuName: 'Wer wir sind',
        cultureMenuName: 'Kultur',
        trainingScheduleName: 'Trainingsplan',
        informationMenuName: 'Information',
        contactUsMenuName: 'Kontakt',
        SocialMediaMenuName: 'Sozialen Medien',
        newsMenuName: 'Nachricht'
    },
    profile: {
        modal: {
            user: 'Benutzerdaten',
            image: 'Profilbild',
            persistSuccess: '%FIELD% wurde erfolgreich gespeichert',
            persistFailed: '%FIELD% konnte nicht gespeichert werden'
        },
        profile: 'Profil',
        associatedProfile: 'Zugehöriges Profil',
        paymentMethod: 'Bezahlverfahren',
        joinCourse: 'Einem Kurs beitreten',
        saveChanges: 'Änderungen speichern',
        memberSince: 'Mitglied seit',
        noCourses: 'Sie haben noch keinen Kurs besucht'
    },
    months: {
        january: 'Januar',
        february: 'Februar',
        march: 'März',
        april: 'April',
        may: 'Mai',
        june: 'Juni',
        july: 'Juli',
        august: 'August',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'Dezember'
    },
    weekday: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag'
    },
    news: {
        more: 'Mehr'
    }
}

export const cmsLanguage = (function () {
    switch (selectedLanguage) {
    case en:
        return cmsLanguageEng
    case de:
        return cmsLanguageDeu
    default:
        return cmsLanguageEng
    }
}())
