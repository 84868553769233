import React, { useEffect, useState } from 'react'
import './Instagram.scss'
import InstagramFeed from 'react-ig-feed'
import { Container, Row } from 'react-bootstrap'
import { NAVIGATION_TYPE } from '../../theme/constants/navigationType'
import NavigationWrapper from '../../theme/commons/navigation_wrapper/NavigationWrapperContainer'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'

/**
 * @typedef InstagramProps
 * @memberOf Instagram
 * @property {string} accessToken
 * @property {string} instagramUrl
 */

/**
 * Shows the last five Instagram posts, with a link to the Instagram page.
 * @class Instagram
 * @category Components
 * @subcategory Templates
 * @param {InstagramProps} props
 * @returns {React.ReactNode}
 * @example
 * const instagramData = reducedElementsPro( template.elements[0], template.fields, null, 'name', true )
 * <Instagram key={template.id} {...instagramData}/>
 */
const Instagram = ({ accessToken, instagramUrl }) => {
    const [isTokenValid, setIsTokenValid] = useState(false)

    useEffect(() => {
        const _checkToken = async () => {
            const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&&access_token=${accessToken}`
            const result = await fetch(url)
            if (result.ok) {
                setIsTokenValid(result.ok)
            }
        }
        _checkToken()
    }, [accessToken])

    return (
        <div className="instagram-template vertical-website-padding horizontal-website-padding">
            <div className="title_container">
                <span className="title">Instagram</span>
                <NavigationWrapper type={NAVIGATION_TYPE.button} text={cmsLanguage.cms.goToInstagram} href={instagramUrl} />
            </div>
            <Container fluid >
                <Row>
                    {isTokenValid && <InstagramFeed token={accessToken} counter="4"/>}
                    {!isTokenValid && <div>Token not valid</div>}
                </Row>
            </Container>
        </div>
    )
}

export default Instagram
