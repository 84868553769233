import { VendorsControllerApi } from '@hexad/vendors-client/dist/apis/VendorsControllerApi'
import { KiosksControllerApi } from '@hexad/vendors-client'
import { MESH_CONFIG } from '../Constants'
import store from '../../../redux/store'

function getAuthToken () {
    return store.getState().user.accessToken
}

const vendorController = new VendorsControllerApi({
    basePath: `${MESH_CONFIG.SERVER_URL}/vendors-service`,
    middleware: [],
    accessToken: () => getAuthToken()
})
const kioskController = new KiosksControllerApi({
    basePath: `${MESH_CONFIG.SERVER_URL}/vendors-service`,
    middleware: [],
    accessToken: () => getAuthToken()
})

export { vendorController, kioskController }
