import React from 'react'
import './InputVideo.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cmsLanguage } from '../../../cms_language/cmsLanguage'

const InputVideo = (props) => {
    const {
        value,
        onChange,
        onFocus,
        onBlur,
        onError
    } = props

    let newValue
    let isValidVideo

    const reYoutube =
    /^(?:https?:\/\/)?(?:(?:www\.)?youtube.com\/watch\?v=|youtu.be\/)([\w-]+)$/
    const reVimeo = /https:\/\/vimeo.com\/\d{8}(?=\b|\/)/

    const validateVideo = (video) =>
        (isValidVideo =
      reYoutube.test(String(video)) || reVimeo.test(String(video)))

    const handleOnChange = (e) => {
        newValue = e.target.value
        onChange(newValue)

        validateVideo(newValue)
        isValidVideo && onError('')
    }

    const handleError = () => {
        validateVideo(value)
        if (!isValidVideo && value.length) {
            onError(
                cmsLanguage.error.notValidVideo + '.'
            )
        }
        onChange(value, !isValidVideo)
        onBlur()
    }

    return (
        <div className="cms-input-box cms-input-box-icon cms-input-video">
            <input
                data-testid="cms-input-video"
                type="url"
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={handleError}
                placeholder={cmsLanguage.cms.videoPlaceholder + '...'}
            />
            <div>
                <FontAwesomeIcon icon={['fa', 'video']} data-testid="cms-input-video-icon" />
            </div>
        </div>
    )
}

export default InputVideo
