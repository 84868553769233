import { reducedElementsPro } from '../../utils/elementsUtils'
import adaptCourseData from '../../templates/course_info/adaptCourseData'
import MeshProducts from '../../backend/mesh/product_service/MeshProducts'
import adaptToPatch, { replace } from '../../utils/patchUtils'
import { EventRelatedProduct } from '../../backend/mesh/domain/Product'
import { formatEventsGroupDataForAPI } from '../../theme/commons/AddEditClass/dataUtils/dataFormatter'
import MeshEventsGroup from '../../backend/mesh/calendar_service/MeshEventsGroup'
import { findInTreeAndApply } from '../../utils/objectUtils'
import MeshEventCategory from '../../backend/mesh/calendar_service/MeshEventCategory'
import { isEqual } from 'lodash'
import store from '../../redux/store'
import { addProduct, updateProduct } from '../actions/CMSActions'
import {
    defaultCoursesHeadingGroup, defaultSportHeadingGroup, initializerMenuData
} from '../../initializerData'
import { templateTypes } from '../cms_templates_bars/components/cms_modal_multiple_elements/constants'
import { trackPromise } from 'react-promise-tracker'

/**
 * Given a content when it has an event related it updates or creates the product related to the event with the data from the (first) COURSE template
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @param {TemplateSection} contentDraft
 * @param {Array<Object>} products
 * @param {function} onAddProduct
 * @param {function} onAddSchedules
 * @param {function} onUpdateProduct
 * @return {Promise<void>}
 */
const onEventContentCreateOrUpdate = async (content, contentDraft) => {
    const isCourseInfoTemplate = contentDraft.templates.find(template => template.type === templateTypes.COURSE) || content.templates.find(template => template.type === templateTypes.COURSE)
    if (isCourseInfoTemplate) {
        return courseHandler(content, contentDraft)
    } else {
        return []
    }
}

const courseHandler = async (content, contentDraft) => {
    const courseTemplates = content.templates.filter(template => template.type === templateTypes.COURSE)
    const draftCourseTemplates = contentDraft.templates.filter(template => template.type === templateTypes.COURSE)
    const allTemplateIds = courseTemplates.map(t => t.id)
    draftCourseTemplates.map(t => t.id).forEach(t => {
        if (allTemplateIds.indexOf(t) === -1) {
            allTemplateIds.push(t)
        }
    })

    return Promise.all(
        allTemplateIds.map((templateId, templateIndex) => {
            const courseTemplate = content.templates.find(t => t.id === templateId)
            const draftTemplate = contentDraft.templates.find(t => t.id === templateId)
            return processEventsGroupTemplate(content, contentDraft, courseTemplate, draftTemplate, templateIndex)
        })
    )
}

const processEventsGroupTemplate = async (content, contentDraft, courseTemplate, draftCourseTemplate, templateIndex) => {
    const onAddProduct = addProduct
    const onUpdateProduct = updateProduct

    const menuInfo = await getMenuInfo(content)
    let eventsGroup = await getEventsGroup(menuInfo, courseTemplate)

    const courseProduct = await findCourseProduct(eventsGroup.categoryId, eventsGroup.id, content.id)

    if (courseHasBeenRemoved(courseTemplate, draftCourseTemplate, eventsGroup.id)) { // Course has been removed. Then remove te connection between EventsGroup and the content should be removed
        await trackPromise(Promise.all([
            MeshEventsGroup.updateEventsGroup({ ...eventsGroup, contentId: null }),
            courseProduct ? MeshProducts.patchProduct([replace('tags', { ...courseProduct.tags, contentId: undefined })], courseProduct.id) : Promise.resolve()
        ]))
        return {
            ...draftCourseTemplate,
            metadata: {
                categoryId: undefined,
                eventsGroupId: undefined
            }
        }
    } else if (draftCourseTemplate) {
        const courseData = adaptCourseData(draftCourseTemplate)
        const formattedData = formatEventsGroupDataForAPI(eventsGroup, courseData, content.id, templateIndex)

        try {
            const response = await trackPromise(MeshEventsGroup.createEventsGroup(formattedData))
            eventsGroup = response.data
        } catch (e) {
            console.warn('Event Creation Failure', e)
        }

        await handleProductCreation(
            content,
            contentDraft,
            courseData,
            eventsGroup,
            courseProduct,
            onAddProduct,
            onUpdateProduct
        )
    }
    return {
        ...draftCourseTemplate,
        metadata: {
            categoryId: eventsGroup.categoryId,
            eventsGroupId: eventsGroup.id
        }
    }
}

const courseHasBeenRemoved = (courseTemplate, draftCourseTemplate, eventsGroupId) => {
    return courseTemplate && !draftCourseTemplate && eventsGroupId
}

export const getMenuInfo = async (content) => {
    const { headerMenu } = store.getState()
    const coursesMenu = headerMenu.children.find(element => element.path === initializerMenuData.headerMenu.path + '/' + defaultCoursesHeadingGroup.path)
    const sportsMenu = headerMenu.children.find(element => element.path === initializerMenuData.headerMenu.path + '/' + defaultSportHeadingGroup.path)
    let foundInSubmenu = false

    let coursesMenuItem, sportsMenuItem, courseSubmenuParentItem, sportsSubmenuParentItem

    await findInTreeAndApply(
        coursesMenu,
        menuItem => menuItem.contentId === content.id || (!!content.menu && menuItem.contentId === content.menu.contentId),
        async eventsGroupMenu => {
            if (eventsGroupMenu.contentId === content.id) {
                coursesMenuItem = eventsGroupMenu
            } else if (eventsGroupMenu.contentId === content.menu.contentId) { // Find also in subMenu of the subpage
                await findInTreeAndApply(
                    content.menu,
                    subMenuItem => subMenuItem.contentId === content.id,
                    eventsGroupSubMenu => {
                        foundInSubmenu = true
                        courseSubmenuParentItem = eventsGroupMenu
                        coursesMenuItem = eventsGroupSubMenu
                        return eventsGroupSubMenu
                    }
                )
            }
            return eventsGroupMenu
        }
    )

    await findInTreeAndApply(
        sportsMenu,
        menuItem => menuItem.contentId === content.id || (!!content.menu && menuItem.contentId === content.menu.contentId),
        async eventsGroupMenu => {
            if (eventsGroupMenu.contentId === content.id) {
                sportsMenuItem = eventsGroupMenu
            } else if (eventsGroupMenu.contentId === content.menu.contentId) { // Find also in subMenu of the subpage
                await findInTreeAndApply(
                    content.menu,
                    subMenuItem => subMenuItem.contentId === content.id,
                    eventsGroupSubMenu => {
                        foundInSubmenu = true
                        sportsSubmenuParentItem = eventsGroupMenu
                        sportsMenuItem = eventsGroupSubMenu
                        return eventsGroupSubMenu
                    }
                )
            }

            return eventsGroupMenu
        }
    )

    if (coursesMenuItem) {
        return {
            category: coursesMenu,
            eventsGroup: coursesMenuItem,
            submenuParentItem: courseSubmenuParentItem,
            foundInSubmenu
        }
    } else if (sportsMenuItem) {
        return {
            category: sportsMenu,
            eventsGroup: sportsMenuItem,
            submenuParentItem: sportsSubmenuParentItem,
            foundInSubmenu
        }
    }
}

const getEventsGroup = async (menuInfo, courseTemplate) => {
    const { category: coursesMenu, eventsGroup: coursesMenuItem, foundInSubmenu, submenuParentItem } = menuInfo
    const eventsGroupId = courseTemplate?.metadata?.eventsGroupId
    const { data: categories } = await MeshEventCategory.getCategories()
    const categoryFound = categories.find(category => category.id === coursesMenu.categoryId)
    const eventsGroupFound = categoryFound.eventsGroups.find(eventsGroup => eventsGroup.id === eventsGroupId)
    let eventsGroup
    if (!eventsGroupFound) {
        eventsGroup = {
            categoryId: coursesMenu.categoryId,
            name: foundInSubmenu ? submenuParentItem.name : coursesMenuItem.name,
            url: foundInSubmenu ? coursesMenuItem.path : coursesMenuItem.path.split('/').slice(1).join('/')
        }
    } else {
        eventsGroup = eventsGroupFound
    }
    return eventsGroup
}

const handleProductCreation = async (
    content,
    contentDraft,
    courseData,
    eventsGroup,
    courseProduct,
    onAddProduct,
    onUpdateProduct
) => {
    const imageUrl = contentDraft.templates[0].elements[0].values.find(v => v.field.type === 'PHOTO').value
    const productDescription = eventsGroup.url
    const productName = eventsGroup.name
    const price = courseData.price

    if (!courseProduct) {
        // Creating a new product and schedule
        try {
            const newProduct = EventRelatedProduct({
                imageUrl,
                name: productName,
                description: productDescription,
                tags: {
                    categoryId: [eventsGroup.categoryId],
                    eventsGroupId: [eventsGroup.id],
                    contentId: [content.id]
                },
                price
            })
            const result = await trackPromise(MeshProducts.createProduct(newProduct))

            result && onAddProduct(result)
        } catch (error) {
            console.error(`The product was not created: ${error}`)
        }
    } else {
        // Editing a product
        const productId = courseProduct.id

        try {
            const response = await trackPromise(MeshProducts.patchProduct(adaptToPatch(getProductChanges(content, contentDraft), 'replace'), productId))
            if (response.status === 200) {
                onUpdateProduct({ ...courseProduct, price, id: productId })
            }
        } catch (error) {
            console.error(`The product was not patched: ${error}`)
        }
    }
}

/**
 * @module onEventContentCreateOrUpdate
 * @category Utils
 * @subcategory CMS / AdministrationBar
 */

/**
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @param {string} templateType
 * @returns {*}
 */
export const reduceElementsByTemplateType = (content, templateType) => {
    const selectedTemplate = content.templates.find(template => template.type === templateType)
    return selectedTemplate?.elements.map((element) =>
        reducedElementsPro(element, selectedTemplate?.fields)
    )[0]
}

/**
 * @function
 * @inner
 * @static
 * @param {TemplateSection} content
 * @param {TemplateSection} contentDraft
 * @returns {Array<*>}
 */
const getProductChanges = (content, contentDraft) => {
    const productDiffFields = []
    const contentCommonBanner = reduceElementsByTemplateType(content, 'COMMON_BANNER')
    const contentDraftCommonBanner = reduceElementsByTemplateType(contentDraft, 'COMMON_BANNER')
    const contentCourse = reduceElementsByTemplateType(content, 'COURSE')
    const contentDraftCourse = reduceElementsByTemplateType(contentDraft, 'COURSE')

    if (!isEqual(contentCommonBanner.H1, contentDraftCommonBanner.H1)) productDiffFields.push(['name', contentDraftCommonBanner.H1])
    if (!isEqual(contentCommonBanner.H2, contentDraftCommonBanner.H2)) productDiffFields.push(['description', contentDraftCommonBanner.H2])
    if (!isEqual(contentCommonBanner.PHOTO, contentDraftCommonBanner.PHOTO)) productDiffFields.push(['image_url', contentDraftCommonBanner.PHOTO])
    if (!isEqual(contentCourse.PRICE, contentDraftCourse.PRICE)) productDiffFields.push(['price', contentDraftCourse.PRICE])
    return productDiffFields
}

const findCourseProduct = async (categoryId, eventsGroupId, contentId) => {
    const products = await MeshProducts.getProducts()
    return products?.find(productObj => {
        return productObj.tags &&
          productObj.tags.eventsGroupId &&
          productObj.tags.eventsGroupId[0] &&
          productObj.tags.categoryId &&
          productObj.tags.categoryId[0] &&
          productObj.tags.contentId &&
          productObj.tags.contentId[0] &&
          productObj.tags.categoryId[0] === categoryId && productObj.tags.eventsGroupId[0] === eventsGroupId && productObj.tags.contentId[0] === contentId
    })
}

export default onEventContentCreateOrUpdate
