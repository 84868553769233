import React from 'react'
import PaymentStep from './PaymentStep'
import { trackPromise } from 'react-promise-tracker'
import { cmsLanguage } from '../../../../../cms/commons/cms_language/cmsLanguage'

/**
 * @const
 * @memberOf PaymentStepContainer
 * @type {number}
 * @example
 * const DEFAULT_COURSE_QUANTITY = 1
 */
const DEFAULT_COURSE_QUANTITY = 1

/**
 * @const
 * @memberOf PaymentStepContainer
 * @type {string}
 * @example
 * const ORDER_SUCCESS = 'SENT'
 */
export const ORDER_SUCCESS = 'SENT'

/**
 * @function
 * @memberOf PaymentStepContainer
 * @param orderResponse
 * @returns {boolean}
 * @example
 * const hasPaid = isOrderPaymentDone(orderResponse)
 */
const isOrderPaymentDone = (orderResponse) => orderResponse?.statusOrder === ORDER_SUCCESS

/**
 * Returns the {@link PaymentStep} component with the function `onNext={handleNext}`.
 * @class PaymentStepContainer
 * @category Containers
 * @subcategory Templates / CourseInfo
 * @param {Object} props
 * @returns {React.ReactNode}
 */
const PaymentStepContainer = (props) => {
    const { vendorId, kioskId, productId, eventsGroupId } = props
    const [errorMessage, setErrorMessage] = React.useState('')
    const checkoutId = new URLSearchParams(window.location.search).get('id')

    /**
     * It handles the order creation process according to the requested product and the payment method
     * @callback PaymentStepContainer~handlePayment
     * @param {string} selectedId - Identifies the payment method
     * @param {PaymentMethod_CreateOrder | PaymentMethod_MockFailedOrder | PaymentMethod_MockSuccessOrder} action - Function that will create an order
     * @param {object | undefined} paymentDetails - VrPayment details when required
     * @return {Promise<boolean>}
     */
    const handlePayment = async (selectedId, action, paymentDetails) => {
        setErrorMessage('')
        const products = [
            {
                vendorId,
                kioskId,
                productId,
                quantity: DEFAULT_COURSE_QUANTITY
            }
        ]

        try {
            const withDetails = selectedId !== 'WALLET'
            const response = await trackPromise(action(products, selectedId, eventsGroupId, withDetails ? paymentDetails : undefined))

            const hasPaid = isOrderPaymentDone(response)
            if (!hasPaid) {
                setErrorMessage(cmsLanguage.cms.orderIsNotAccepted)
            }
            return hasPaid
        } catch (e) {
            setErrorMessage(cmsLanguage.cms.orderNotReceived)
            return false
        }
    }
    return <PaymentStep {...props} checkoutId={checkoutId} onPay={handlePayment} errorMessage={errorMessage} />
}

export default PaymentStepContainer
