import { CONFIG, MESH_CONFIG } from '../Constants'
import store from '../../../redux/store'
// eslint-disable-next-line no-unused-vars
import axios, { AxiosResponse } from 'axios'

/**
 * @const
 * @alias PRODUCT_AVAILABLE
 * @type {number}
 * @memberOf MeshProducts
 * @example
 * const PRODUCT_AVAILABLE = 1
 */
export const PRODUCT_AVAILABLE = 1

/**
 * @class MeshProducts
 * @category Backend
 * @subcategory product_service
 */
export default class MeshProducts {
    /**
     * @function
     * @memberOf MeshProducts
     * @returns {{config: {headers: {Authorization: string, 'x-api-key': string, CONTENT_TYPE: string}}, url: string}}
     * @static
     */
    static baseData () {
        const { accessToken, organizationId } = store.getState().user
        return {
            url: `${MESH_CONFIG.SERVER_URL}/products-service/organizations/${organizationId}/products`,
            config: {
                headers: {
                    ...CONFIG.DEFAULT_HEADERS,
                    CONTENT_TYPE: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }
        }
    }

    /**
     * Creates a new product
     * @function
     * @memberOf MeshProducts
     * @param name {string} - Which product
     * @param image_url {string} - Image
     * @param tags {object} - Extra tags
     * @param description {string} - Little description or related data
     * @param price {number} - how much
     * @param stock {number} - how many
     * @param status {(0|1)} - availability 1, 0 out of stock
     * @returns {Promise<Product>}
     * @static
     */
    static async createProduct ({
        name,
        imageUrl,
        tags,
        description,
        price,
        stock,
        status = 1
    }) {
        const url = MeshProducts.baseData().url
        const data = {
            name,
            image_url: imageUrl,
            tags,
            description,
            price,
            stock,
            status
        }
        const config = MeshProducts.baseData().config
        const response = await axios.post(url, data, config)
        return response.data
    }

    /**
     * Gets all products
     * @function
     * @memberOf MeshProducts
     * @returns {Promise<Product>}
     * @static
     */
    static async getProducts () {
        const url = MeshProducts.baseData().url
        const config = MeshProducts.baseData().config
        const response = await axios.get(url, config)
        return response.data
    }

    /**
     * Updates a specific product by its ID
     * @function
     * @memberOf MeshProducts
     * @param {Product} productData
     * @param {string} id
     * @returns {Promise<AxiosResponse<any>>}
     * @static
     */
    static async patchProduct (productData, id) {
        const url = MeshProducts.baseData().url + `/${id}`
        const config = MeshProducts.baseData().config
        config.headers['Content-Type'] = 'application/json-patch+json'
        return await axios.patch(url, productData, config)
    }

    static async searchProduct (requestSample) {
        const baseData = MeshProducts.baseData()
        const url = `${baseData.url}/search`
        const config = MeshProducts.baseData().config
        const response = await axios.post(url, requestSample, config).catch(e => ({ data: [] }))
        return response.data
    }
}
