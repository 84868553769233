import React from 'react'
import './VideoTemplate.scss'
import { isEmpty } from '../../cms/util/Util'

/**
 * @typedef VideoTemplateProps
 * @memberOf VideoTemplate
 * @property {Array<Object>} videos
 */

/**
 * Receives an array of videos and displays them on the screen using YouTube or Vimeo player.
 * @class VideoTemplate
 * @category Components
 * @subcategory Templates
 * @param {VideoTemplateProps} props
 * @returns {React.ReactNode}
 * @example
 * <VideoTemplate videos={template.elements} />
 */
const VideoTemplate = ({ videos }) => {
    const reYoutube =
        /^(?:https?:\/\/)?(?:(?:www\.)?youtube.com\/watch\?v=|youtu.be\/)(\w+)$/
    const reVimeo = /https:\/\/vimeo.com\/(\d{7,8})(?=\b|\/)/

    /**
     * Checks if video is YouTube or Vimeo, and prepare it to be displayed.
     * @function
     * @memberOf VideoTemplate
     * @param {Element} element
     * @returns {*}
     */
    const parseVideoElement = element => {
        return element.values.reduce((acc, item) => {
            const key = item.field.name.toLowerCase()
            let value = item.value
            if (key === 'video') {
                const youtubeMatch = value.match(reYoutube)
                if (youtubeMatch && !isEmpty(youtubeMatch[1])) {
                    const videoId = youtubeMatch[1]
                    value = `https://www.youtube.com/embed/${videoId}?controls=0`
                }
                const vimeoMatch = value.match(reVimeo)
                if (vimeoMatch && !isEmpty(vimeoMatch[1])) {
                    const videoId = vimeoMatch[1]
                    value = `https://player.vimeo.com/video/${videoId}`
                }
            }
            acc[key] = value
            return acc
        }, {})
    }

    return (
        <>
            {videos.map(v => parseVideoElement(v)).map((v, i) => (
                <div className='video-player'
                    key={i}>
                    <iframe className='video-player-frame' key={i} src={v.video + '&output=embed'} title="video-player"/>
                    <div className='video-player-info horizontal-website-padding'>
                        {v.text &&
                            <h2 className='video-player-info-text'>{v.text}</h2>
                        }
                        {v.date &&
                            <p className='video-player-info-date'>{v.date}</p>
                        }
                    </div>
                </div>
            ))}
        </>
    )
}

export default VideoTemplate
