import React, { useEffect, useState } from 'react'
import CourseInfo from './CourseInfo'

import { useStepRedirection } from './hooks'
import MeshProducts from '../../backend/mesh/product_service/MeshProducts'
import MeshEventsGroup from '../../backend/mesh/calendar_service/MeshEventsGroup'
import { vendorController } from '../../backend/mesh/vendors_service/MeshVendors'
import store from '../../redux/store'

/**
 * {@link CourseInfo} container that binds the CourseInfo with the {@link module:reduxSetup}
 * @class CourseInfoContainer
 * @category Containers
 * @subcategory Templates / CourseInfo
 *
 * @mermaid
 *   sequenceDiagram
 *   Parent->>CourseInfoContainer: Request the CourseInfo via CourseInfoContainer.
 *   CourseInfoContainer->>reduxStore: CourseInfoContainer gets information from redux store.
 *   reduxStore->>CourseInfoContainer: The redux store information to CourseInfoContainer as props.
 *   CourseInfoContainer->>CourseInfo: CourseInfoContainer render the CourseInfo with new props.
 *   CourseInfo-->>CourseInfoContainer: CourseInfo is rendered inside the CourseInfoContainer.
 *   CourseInfoContainer-->>Parent: CourseInfoContainer is rendered in the parent component.
 */

export const CourseInfoContainer = (props) => {
    const [course, setCourse] = useState()
    const [product, setProduct] = useState()
    const [vendors, setVendors] = useState()

    useEffect(() => {
        const _getProduct = async () => {
            if (props.categoryId && props.eventsGroupId && props.contentId) {
                const products = await MeshProducts.searchProduct({ tags: { categoryId: [props.categoryId], eventsGroupId: [props.eventsGroupId], contentId: [props.contentId] } })
                if (products && products.length > 0) {
                    setProduct(products[0])
                }
            } else {
                setProduct({})
            }
        }
        const _getCourse = async () => {
            if (props.eventsGroupId) {
                const course = await MeshEventsGroup.findByEventsGroupId(props.eventsGroupId)
                setCourse(course.data)
            } else {
                setCourse({})
            }
        }
        const _getVendors = async () => {
            const { organizationId } = store.getState().user
            const response = await vendorController.getVendors({ authorization: '', organizationId: organizationId })
            setVendors(response)
        }

        _getProduct()
        _getCourse()
        _getVendors()
    }, [])

    const [storedStep] = useStepRedirection()
    if (storedStep === undefined) {
        console.debug('Waiting step redirection')
        return <></>
    }
    const isLoading = () => {
        return !product || !course
    }

    const getVendorId = () => {
        if (product.inventory && product.inventory.length > 0 && product.inventory[0].vendorId) {
            return product.inventory[0].vendorId
        } else if (vendors && vendors.length > 0) {
            return vendors[0].id
        } else {
            return ''
        }
    }
    const getKioskId = () => {
        if (product && product.inventory && product.inventory.length > 0 && product.inventory[0].kioskId) {
            return product.inventory[0].kioskId
        } else if (vendors && vendors.length > 0 && vendors[0].kiosks && vendors[0].kiosks.length > 0) {
            return vendors[0].kiosks[0].id
        } else {
            return ''
        }
    }

    return !isLoading() && (
        <CourseInfo
            {...props}
            courseName={course?.name}
            date={props.date}
            description={props.description}
            duration={props.duration}
            vendorId={getVendorId()}
            kioskId={getKioskId()}
            productId={product?.id}
            price={props.price !== product?.price ? props.price : product?.price}
            trainers={props.trainers}
            courseId={course?.id || ''}
            step={storedStep}
        />
    )
}

/**
 * Gets the `content`
 * from redux state and makes available to the {@link CourseInfoContainer} as a prop called `courseName`.
 * @function
 * @memberOf CourseInfoContainer
 * @alias mapStateToProps
 * @param {Object} state - redux state
 * @returns {Object}
 */

export default CourseInfoContainer
