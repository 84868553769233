// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".footer-container{background-color:#191919;border-bottom:solid .625em #64b42d;padding-top:1.875em}.footer-container .container-fluid{padding-left:0;padding-right:0}.footer-container .row{margin-right:0;margin-left:0}.footer-container>.row{padding-bottom:3.75em}.footer-container .col{padding-left:0;padding-right:0}.footer-container .footer-logo-container{margin-bottom:3.75em}.footer-container-bottom{justify-content:space-between}.company-name{font-family:'Open Sans', sans-serif;font-weight:normal;font-style:normal;font-size:1.375em;color:#fff}.company-name span{font-family:'Open Sans', sans-serif;font-weight:700;font-style:normal}@media screen and (max-width: 768px){.footer-container-bottom .col:first-child{margin-bottom:1.875em}}\n", ""]);
// Exports
exports.locals = {
	"main": "#64b42d",
	"red": "#f03",
	"yellow": "#fc3",
	"fontP2": "18",
	"width_xxs": "320",
	"width_xs": "576",
	"width_md": "768",
	"width_lg": "992",
	"width_xl": "1200",
	"width_xxl": "1400",
	"width_xxxl": "1920",
	"width_qhd": "2560",
	"width_wqhd": "3440",
	"responsiveMenuBreakpoint": "1199",
	"sizeS": "15",
	"sizeM": "30",
	"sizeL": "45"
};
module.exports = exports;
