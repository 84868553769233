import React from 'react'
import './courseInfo.scss'
import JoinCourseModal from './components/join_course_modal'
import EventDescription from './components/event_description'

import { language } from '@theme/commons/language/language'
import { cmsLanguage } from '../../cms/commons/cms_language/cmsLanguage'
import JOIN_COURSE_STEP, { TOTAL_STEPS } from './joinCourseStep'

/**
 * @const
 * @memberOf CourseInfo
 * @type {string}
 * @example
 * const CURRENCY = '€'
 */
const CURRENCY = '€'

/**
 * @typedef CourseInfoProps
 * @memberOf CourseInfo
 * @property {string} courseName
 * @property {string} courseId
 * @property {string} date
 * @property {string} description
 * @property {number} duration
 * @property {string} price
 * @property {Array<Trainer>} [trainers]
 * @property {string} productId
 * @property {boolean} isJoined
 * @property {number} step
 */

/**
 * Course information with {@link JoinCourseModal} and {@link EventDescription}
 * @class CourseInfo
 * @category Components
 * @subcategory Templates / CourseInfo
 * @param {CourseInfoProps} props
 * @returns {React.ReactNode}
 * @example
 * <CourseInfo
 *     courseName='Yoga'
 *     date='21-04-2022'
 *     description='Lorem ipsum dolor sit amet'
 *     duration='3'
 *     price='20'
 *     trainers={[]}
 * />
 */
const CourseInfo = ({
    courseName,
    categoryId,
    eventsGroupId,
    date,
    description,
    duration,
    durationUnit,
    endingDate,
    location,
    repeatPeriod,
    repeatType,
    price,
    trainers = [],
    vendorId,
    kioskId,
    productId,
    step,
    shortDescription
}) => {
    const [currentStep, setCurrentStep] = React.useState(step)

    /**
    * Steps go back to initial (0)
    */
    const onClose = () => setCurrentStep(JOIN_COURSE_STEP.INITIAL)

    /**
    * Go to the next step or to the first (cyclic)
    */
    const handleOnNext = () => {
        const nextStep = (currentStep + 1) % TOTAL_STEPS
        setCurrentStep(nextStep)
    }

    return (
        <div className="course-info-container">
            {currentStep > JOIN_COURSE_STEP.INITIAL && (
                <JoinCourseModal
                    courseName={courseName}
                    eventsGroupId={eventsGroupId}
                    vendorId={vendorId}
                    kioskId={kioskId}
                    productId={productId}
                    onClose={onClose}
                    onNext={handleOnNext}
                    price={price}
                    step={currentStep}
                />
            )}
            <div className="course-info vertical-website-padding horizontal-website-padding">
                <EventDescription
                    date={date}
                    disabled={false}
                    description={description}
                    shortDescription={shortDescription}
                    duration={duration}
                    durationUnit={durationUnit}
                    endingDate={endingDate}
                    location={location}
                    repeatPeriod={repeatPeriod}
                    repeatType={repeatType}
                    price={price}
                    involvedPeopleData={{
                        list: trainers,
                        label: cmsLanguage.inputFields.trainers
                    }}
                    joinLabel={language.modals.joinCourse}
                    trainers={trainers}
                    currency={CURRENCY}
                    scheduleData={{ link: '/training', label: language.templates.viewClassSchedule }}
                    onClickJoin={handleOnNext}
                    categoryId={categoryId}
                    eventsGroupId={eventsGroupId}
                />
            </div>
        </div>
    )
}

export default CourseInfo
